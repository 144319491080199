import { Divider, Stack, Text, Title, Transition, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import useNetworkType from '@/hooks/useNetworkType'

import OpenMenuArrow from '../Animations/OpenMenuArrow/OpenMenuArrow'
import RootButton from '../Buttons/RootButton'
import SimulateNetworkTool from '../SimulateNetworkTool'
import { AIRDROP_GUIDE } from './utils'

interface ToggleDetailsProps {
  title: string | JSX.Element
  onClick: () => void
  open: boolean
  children: React.ReactNode
  isError?: boolean
}

export const ToggleDetails: React.FC<ToggleDetailsProps> = ({
  title,
  onClick,
  open,
  children,
  isError = false,
}) => {
  const theme = useMantineTheme()
  const scaleY = {
    in: { opacity: 1, transform: 'scaleY(1)' },
    out: { opacity: 0, transform: 'scaleY(0)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  }

  return (
    <Stack style={{ gap: '0px' }}>
      <Stack
        style={{ flexDirection: 'row', justifyContent: 'space-between', cursor: 'pointer' }}
        onClick={onClick}
      >
        {typeof title === 'string' ? (
          <Text
            style={{
              color: theme.colors.title[0],
              fontFamily: isError ? 'monument-grotesk-bold' : '',
            }}
          >
            {title}
          </Text>
        ) : (
          title
        )}
        <OpenMenuArrow menuOpened={open} />
      </Stack>
      <Transition
        mounted={open}
        transition={scaleY}
        duration={300}
        timingFunction="ease"
        keepMounted
      >
        {transitionStyle => <div style={{ ...transitionStyle }}>{children}</div>}
      </Transition>
    </Stack>
  )
}

interface StartAirdropProps {
  nextStep: () => void
}
const StartAirdrop: React.FC<StartAirdropProps> = ({ nextStep }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { isBetanet } = useNetworkType()

  interface AirdropDetails {
    windows: boolean
    eligibility: boolean
    guide: boolean
  }

  const [openMore, setOpenMore] = useState<AirdropDetails>({
    windows: false,
    eligibility: false,
    guide: false,
  })

  const toggleWindows = useCallback(
    () => setOpenMore({ ...openMore, windows: !openMore.windows }),
    [openMore]
  )

  const toggleEligibility = useCallback(
    () => setOpenMore({ ...openMore, eligibility: !openMore.eligibility }),
    [openMore]
  )

  return (
    <Stack style={{ gap: 40 }}>
      <Divider />
      <SimulateNetworkTool />
      <Stack style={{ gap: '20px' }}>
        <Title order={isMobile ? 3 : 2}>
          {isBetanet
            ? t('components.airdrop.checkAirdropBetanet', 'Check Your Airdrop')
            : t('components.airdrop.claimAirdrop', 'Claim Your Airdrop')}
        </Title>
        <Text style={{ fontSize: 14 }}>
          {isBetanet
            ? t(
                'components.airdrop.claimAirdropDescriptionBetanet',
                'If you participated in Betanet, your account here is likely eligible for ADb Airdrop rewards. Use this flow to check your rewards status and forward any rewards you are eligible for over to your Mainnet account.'
              )
            : t(
                'components.airdrop.claimAirdropDescription',
                'Participate in Betanet? You may be eligible for an airdrop of community and platform rewards.'
              )}
        </Text>
        <Stack style={{ flexDirection: 'row' }}>
          <RootButton onClick={nextStep} expand>
            {t('buttons.checkHub', 'Check Hub Account')}
          </RootButton>
        </Stack>
        <Stack style={{ gap: '5px' }}>
          <Title order={4} style={{ marginBottom: 15 }}>
            {t('components.airdrop.details', 'Airdrop Details')}
          </Title>

          <ToggleDetails
            title={t('components.airdrop.claim.1', 'Claim Window')}
            onClick={toggleWindows}
            open={openMore.windows}
          >
            <Text style={{ fontSize: 14 }}>
              {t('components.airdrop.claim.1description', 'September 12 - September 26, 2024')}
            </Text>
          </ToggleDetails>
          <ToggleDetails
            title={t('components.airdrop.claim.2', 'Eligibility')}
            onClick={toggleEligibility}
            open={openMore.eligibility}
          >
            <Text style={{ fontSize: 14 }}>
              {isBetanet
                ? t(
                    'components.airdrop.claim.2descriptionBetanet',
                    'To be eligible for platform rewards, you must have created a LAMINA1 Hub account here, on Betanet prior to April 18, 2024 and made at least one transaction on the Betanet chain.'
                  )
                : t(
                    'components.airdrop.claim.2description',
                    'To be eligible for community rewards, you must have joined the LAMINA1 Discord before the Quest cut-off window of April 26, 2024. For platform rewards, you must have created a LAMINA1 Hub account on Betanet prior to April 18, 2024.'
                  )}
            </Text>
          </ToggleDetails>
          <Text style={{ fontSize: 14 }}>
            <Trans i18nKey="components.airdrop.claim.guide">
              See the{' '}
              <a href={AIRDROP_GUIDE} target="_blank" rel="noreferrer">
                ADb Airdrop Guide
              </a>{' '}
              for more details.
            </Trans>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default StartAirdrop
