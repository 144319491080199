import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const SENTRY_DSN =
  'https://c3aad5fcf8925fc686ccd1dc92b1c46b@o4505901897547776.ingest.us.sentry.io/4505901899317248'

const IGNORE_ERRORS = [
  'Cannot redefine property: request',
  'Cannot redefine property: ethereum',
  'Load failed',
]

const SENTRY_ENVIRONMENT = process.env.SENTRY_ENV || 'development'

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  beforeSend(event) {
    if (event.message && IGNORE_ERRORS.includes(event.message)) {
      return null
    }
    if (event.tags && event.tags.ignore_error === 'true') {
      return null
    }

    return event
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/lamina1\.com\//, /^https:\/\/lamina1\.network\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
// export default sentryApolloLink = new SentryLink();
