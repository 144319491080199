import { Burger, Divider, Drawer, Flex, Stack, Text, useMantineTheme } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconChevronRight } from '@tabler/icons-react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import l1TokenBlack from '@/assets/tokens/l1TokenBlack.svg'
import l1TokenWhite from '@/assets/tokens/l1TokenWhite.svg'
import Web3Button from '@/components/Buttons/Web3Button'
import { useChainFeaturesContext } from '@/contexts/ChainFeaturesContext'
import { useAuth } from '@/plugins/auth'
import { saveLocation } from '@/plugins/auth/RouteRedirectors/utils'

import airdropBlackIcon from '../../assets/icons/airdrop_black.svg'
import airdropBlueIcon from '../../assets/icons/airdrop_blue.svg'
import airdropGreenIcon from '../../assets/icons/airdrop_green.svg'
import airdropWhiteIcon from '../../assets/icons/airdrop_white.svg'
import governanceBlackIcon from '../../assets/icons/governance_black.svg'
import governanceBlueIcon from '../../assets/icons/governance_blue.svg'
import governanceGreenIcon from '../../assets/icons/governance_green.svg'
import governanceWhiteIcon from '../../assets/icons/governance_white.svg'
import homeBlackIcon from '../../assets/icons/home_black.svg'
import homeBlueIcon from '../../assets/icons/home_blue.svg'
import homeGreenIcon from '../../assets/icons/home_green.svg'
import homeWhiteIcon from '../../assets/icons/home_white.svg'
import itemsBlackIcon from '../../assets/icons/items_black.svg'
import itemsBlueIcon from '../../assets/icons/items_blue.svg'
import itemsGreenIcon from '../../assets/icons/items_green.svg'
import itemsWhiteIcon from '../../assets/icons/items_white.svg'
import studioBlackIcon from '../../assets/icons/studio_black.svg'
import studioBlueIcon from '../../assets/icons/studio_blue.svg'
import studioGreenIcon from '../../assets/icons/studio_green.svg'
import studioWhiteIcon from '../../assets/icons/studio_white.svg'
import blackLogo from '../../assets/webp_converted/lamina1_black.webp'
import whiteLogo from '../../assets/webp_converted/lamina1_white.webp'
import Footer from '../Footer'
import IconWrapper from '../MediaWrappers/IconWrapper'
import PageSelector from '../PageSelector'
import UserHeader from '../User/UserHeader'
import classes from './Header.module.css'
import HeaderIconWrapper from './HeaderIconWrapper'

interface BurgerButtonProps {
  toggle: () => void
  close: () => void
  pageName: string
  pagePath: string
  iconDark: string
  iconLight: string
  publicMenuRoutes: string[]
}

const BurgerButton: React.FC<BurgerButtonProps> = ({
  toggle,
  close,
  pageName,
  pagePath,
  iconLight,
  iconDark,
  publicMenuRoutes = [],
}) => {
  const { status, connect } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useMantineTheme()

  const handleNavigation = useCallback(() => {
    saveLocation(pagePath)
    if (status === 'disconnected') {
      if (!publicMenuRoutes.includes(pagePath)) {
        connect()
        close()
        toggle()
      } else {
        navigate(pagePath)
        close()
        toggle()
      }
    }
    if (status === 'connected') {
      navigate(pagePath)
      close()
      toggle()
    }
  }, [navigate, status, connect, pagePath])

  return (
    <>
      <Divider className={classes.divider} />
      <Stack className={classes.burgerButton} onClick={handleNavigation}>
        <Stack className={classes.burgerIconAndText}>
          <IconWrapper classes={classes.iconBurger} iconDark={iconDark} iconLight={iconLight} />
          <Text
            style={{
              color: pathname === pagePath ? theme.colors.l1Primary[0] : theme.colors.title[0],
              fontFamily: 'monument-grotesk-heavy, sans-serif',
              fontSize: '14px',
            }}
            className={classes.burgerButtonText}
          >
            {pageName.toUpperCase()}
          </Text>
        </Stack>
        <IconChevronRight size={20} />
      </Stack>
    </>
  )
}

interface HoveredHeaderButtonProps {
  pageName: string
  pagePath: string
  iconDark: string
  iconLight: string
  hoveredIconDark: string
  hoveredIconLight: string
  publicMenuRoutes: string[]
}

const HoveredHeaderButton: React.FC<HoveredHeaderButtonProps> = ({
  pageName,
  pagePath,
  iconDark,
  iconLight,
  hoveredIconDark,
  hoveredIconLight,
  publicMenuRoutes = [],
}) => {
  const { pathname } = useLocation()
  const { status, connect } = useAuth()
  const iconOnly = useMediaQuery(`(max-width:1330px)`)
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const ref = useRef(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const handleHoverEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleHoverLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const handleNavigation = useCallback(() => {
    saveLocation(pagePath)
    if (status === 'disconnected') {
      if (!publicMenuRoutes.includes(pagePath)) connect()
      else navigate(pagePath)
    }
    if (status === 'connected') {
      navigate(pagePath)
    }
  }, [navigate, status, connect, pagePath])

  return (
    <div
      ref={ref}
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
      style={{ width: 'fit-content', height: 'fit-content' }}
    >
      <PageSelector
        iconOnly={iconOnly}
        icon={
          <HeaderIconWrapper
            selected={pathname === pagePath}
            hovered={isHovered}
            iconDark={iconDark}
            iconLight={iconLight}
            hoveredIconDark={hoveredIconDark}
            hoveredIconLight={hoveredIconLight}
          />
        }
        text={pageName.toUpperCase()}
        bold
        onClick={handleNavigation}
        active={pathname === pagePath}
        fontSize="16px"
        hover
        deselectedColorText={theme.colors.title[0]}
      />
    </div>
  )
}

interface HeaderProps {
  children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()

  const { status } = useAuth()
  const { hasFeature } = useChainFeaturesContext()
  const publicMenuRoutes = useMemo(() => {
    const routes = ['/home', '/studio']
    if (hasFeature('governance')) {
      routes.push('/governance')
    }
    return routes
  }, [hasFeature])

  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: 910px)`)

  const [iconOpened, { toggle }] = useDisclosure()
  const [drawerOpened, { open, close }] = useDisclosure(false)

  const lamina1Logo = useMemo(
    () => (
      <IconWrapper
        key="desktop"
        classes={classes.logo}
        iconDark={whiteLogo}
        iconLight={blackLogo}
      />
    ),
    [classes.logo]
  )

  const handleLogoNavigation = useCallback(() => {
    saveLocation('/home')
    navigate('/home')
  }, [navigate])

  return (
    <div
      // Need to add this style to fix the footer issue
      className={classes.header}
    >
      <>
        <Flex className={classes.headerFlexRow} direction="row">
          <Stack align="flex-start">
            <Drawer.Root
              opened={drawerOpened}
              onClose={() => {
                close()
                toggle()
              }}
              size="auto"
              position="left"
              className={classes.drawer}
              lockScroll={false}
            >
              <Drawer.Overlay />
              <Drawer.Content>
                <Drawer.Header className={classes.burgerHeader}>
                  <Drawer.Title className={classes.burgerTitle}>{lamina1Logo}</Drawer.Title>
                  <Drawer.CloseButton className={classes.burgerCloseButton} />
                </Drawer.Header>
                <Drawer.Body className={classes.burgerBody}>
                  <Flex className={classes.headerButtonSetBurger} direction="column">
                    <BurgerButton
                      toggle={toggle}
                      close={close}
                      pageName={t('components.header.home', 'Home')}
                      pagePath="/home"
                      iconLight={homeBlackIcon}
                      iconDark={homeWhiteIcon}
                      publicMenuRoutes={publicMenuRoutes}
                    />
                    <BurgerButton
                      toggle={toggle}
                      close={close}
                      pageName={t('components.studio.button', 'Studio')}
                      pagePath="/studio"
                      iconLight={studioBlackIcon}
                      iconDark={studioWhiteIcon}
                      publicMenuRoutes={publicMenuRoutes}
                    />
                    <BurgerButton
                      toggle={toggle}
                      close={close}
                      pageName={t('components.header.items', 'Items')}
                      pagePath="/items"
                      iconLight={itemsBlackIcon}
                      iconDark={itemsWhiteIcon}
                      publicMenuRoutes={publicMenuRoutes}
                    />
                    <BurgerButton
                      toggle={toggle}
                      close={close}
                      pageName={t('components.header.wallet', 'Wallet')}
                      pagePath="/wallet"
                      iconLight={l1TokenBlack}
                      iconDark={l1TokenWhite}
                      publicMenuRoutes={publicMenuRoutes}
                    />
                    {hasFeature('governance') && (
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.header.governance', 'Governance')}
                        pagePath="/governance"
                        iconLight={governanceBlackIcon}
                        iconDark={governanceWhiteIcon}
                        publicMenuRoutes={publicMenuRoutes}
                      />
                    )}
                    {hasFeature('airdrop') && (
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.header.airdrop', 'Airdrop')}
                        pagePath="/airdrop"
                        iconLight={airdropBlackIcon}
                        iconDark={airdropWhiteIcon}
                        publicMenuRoutes={publicMenuRoutes}
                      />
                    )}
                    <Divider className={classes.divider} />
                  </Flex>
                </Drawer.Body>
              </Drawer.Content>
            </Drawer.Root>
            {isMobile && (
              <Burger
                className={classes.burger}
                color={theme.colors.title[0]}
                key="mobile"
                opened={iconOpened}
                onClick={() => (!iconOpened ? (open(), toggle()) : (close(), toggle()))}
                aria-label={t('components.header.burgerLabel', 'Toggle navigation')}
              />
            )}
            {!isMobile && (
              <Text style={{ alignItems: 'center' }} onClick={handleLogoNavigation}>
                {lamina1Logo}
              </Text>
            )}
          </Stack>
          {!isMobile && (
            <Flex className={classes.headerButtonSet} gap="50px" justify="flex-start">
              <HoveredHeaderButton
                pageName={t('components.header.home', 'Home')}
                pagePath="/home"
                iconDark={homeBlackIcon}
                iconLight={homeWhiteIcon}
                hoveredIconDark={homeGreenIcon}
                hoveredIconLight={homeBlueIcon}
                publicMenuRoutes={publicMenuRoutes}
              />
              <HoveredHeaderButton
                pageName={t('components.header.studio', 'Studio')}
                pagePath="/studio"
                iconDark={studioBlackIcon}
                iconLight={studioWhiteIcon}
                hoveredIconDark={studioGreenIcon}
                hoveredIconLight={studioBlueIcon}
                publicMenuRoutes={publicMenuRoutes}
              />
              <HoveredHeaderButton
                pageName={t('components.header.items', 'Items')}
                pagePath="/items"
                iconDark={itemsBlackIcon}
                iconLight={itemsWhiteIcon}
                hoveredIconDark={itemsGreenIcon}
                hoveredIconLight={itemsBlueIcon}
                publicMenuRoutes={publicMenuRoutes}
              />
              {hasFeature('governance') && (
                <HoveredHeaderButton
                  pageName={t('components.header.governance', 'Governance')}
                  pagePath="/governance"
                  iconDark={governanceBlackIcon}
                  iconLight={governanceWhiteIcon}
                  hoveredIconDark={governanceGreenIcon}
                  hoveredIconLight={governanceBlueIcon}
                  publicMenuRoutes={publicMenuRoutes}
                />
              )}

              {hasFeature('airdrop') && (
                <HoveredHeaderButton
                  pageName={t('components.header.airdrop', 'Airdrop')}
                  pagePath="/airdrop"
                  iconDark={airdropBlackIcon}
                  iconLight={airdropWhiteIcon}
                  hoveredIconDark={airdropGreenIcon}
                  hoveredIconLight={airdropBlueIcon}
                  publicMenuRoutes={publicMenuRoutes}
                />
              )}
            </Flex>
          )}

          <Flex className={classes.userInfoContainer} justify="flex-end">
            {status === 'connected' ? <UserHeader /> : <Web3Button />}
          </Flex>
        </Flex>
        {!children ? <Outlet /> : children}
      </>

      <Footer />
    </div>
  )
}

export default Header
