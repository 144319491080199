import {
  ActionIcon,
  Center,
  Space,
  Stack,
  StackProps,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { QRCodeSVG } from 'qrcode.react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import qrcodeIcon from '../../assets/icons/qrcode.svg'
import l1QrLogo from '../../assets/webp_converted/L1logo_lg_512_light.webp'
import useLocalStorage from '../../hooks/useLocalStorage'
import { shortenHash, truncateIdentity, UserIdentity } from '../../utils/utils'
import CopyButton from '../Buttons/CopyButton'
import classes from './AddressDisplay.module.css'

const QRCodeBox: React.FC<{ address: string }> = ({ address }) => {
  const { t } = useTranslation()

  return (
    <Stack style={{ gap: '0px' }}>
      <Title order={4} style={{ margin: 0 }}>
        {t('components.qrCode.title', 'Hub Address QR Code')}
      </Title>
      <Text style={{ fontSize: '14px' }}>
        {t('components.qrCode.text', 'Share your LAMINA1 Hub via QR Code.')}
      </Text>
      <Space h={20} />
      <Center className={classes.qrCodeBoxSvg}>
        <QRCodeSVG
          value={address}
          size={250}
          level="H"
          imageSettings={{
            src: l1QrLogo,
            x: undefined,
            y: undefined,
            height: 70,
            width: 70,
            excavate: true,
          }}
        />
      </Center>
      <Space h={20} />
    </Stack>
  )
}

interface AddressDisplayProps extends StackProps {
  address: string
  showIdentity?: boolean
  toggleIdentity?: boolean
  truncated?: boolean
  showQrCode?: boolean
  fixedWidth?: string
  tip?: string
  copyButton?: boolean
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({
  address,
  showIdentity = true,
  truncated = true,
  toggleIdentity = false,
  showQrCode = false,
  fixedWidth = '',
  tip = '',
  copyButton = true,
  ...props
}: any) => {
  const [overwriteShowIdentity, setOverwriteShowIdentity] = React.useState(showIdentity)
  const [userIdentity] = useLocalStorage<UserIdentity>(`userIdentity-${address}`)
  const theme = useMantineTheme()

  const identity = useMemo(() => {
    if (userIdentity?.username) {
      return userIdentity.username
    }
    setOverwriteShowIdentity(false)
    return ''
  }, [userIdentity])

  const displayAddress = truncated ? shortenHash(address) : address

  const openQrModal = (qrCodeAddress: string) => {
    modals.openContextModal({
      lockScroll: false,
      modal: 'qrCode',
      centered: true,
      size: 358,
      innerProps: {
        modalBody: <QRCodeBox address={qrCodeAddress} />,
      },
    })
  }

  return (
    <Stack style={{ gap: 5, width: fixedWidth || '100%' }}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: fixedWidth || '100%',
        }}
      >
        <Tooltip
          withArrow
          label={identity}
          style={{ display: identity.length > 13 ? 'inline' : 'none' }}
        >
          <TextInput
            style={{
              // TODO - Mantine: does not work here. Check another way to style input
              // input: {
              //   textAlign: 'left',
              //   backgroundColor: style2 ? theme.colors.background3[0] : 'transparent',
              //   fontSize: '15px',
              //   color: theme.colors.title[0],
              //   border: style2 ? 'none' : `2px solid ${theme.colors.textAndIcon[0]}`,
              //   fontFamily: userIdentity?.username ? 'monument-grotesk-bold' : 'monospace',
              //   padding: '0.75em',
              // },
              width: showQrCode ? '80%' : '100%',
            }}
            radius="12px"
            readOnly
            value={overwriteShowIdentity ? `@${truncateIdentity(identity)}` : displayAddress}
            onFocus={event => event.currentTarget.blur()}
            rightSection={
              <Stack
                style={{
                  flexDirection: 'row',
                  gap: '0',
                }}
              >
                {copyButton && <CopyButton value={overwriteShowIdentity ? identity : address} />}
                {toggleIdentity && identity && (
                  <ActionIcon
                    onClick={() => setOverwriteShowIdentity((previous: boolean) => !previous)}
                    className={classes.rightActionButton}
                  >
                    {overwriteShowIdentity ? <IconEye size={18} /> : <IconEyeOff size={18} />}
                  </ActionIcon>
                )}
              </Stack>
            }
            {...props}
          />
        </Tooltip>

        {showQrCode && (
          <ActionIcon
            className={classes.qrCodeButton}
            radius="16px"
            variant="outline"
            onClick={() => openQrModal(address)}
            size={36}
          >
            <img src={qrcodeIcon} width={15} height={15} alt="qr code icon" />
          </ActionIcon>
        )}
      </Stack>
      {tip && <Text style={{ color: theme.colors.textAndIcon[0], fontSize: 14 }}>{tip}</Text>}
    </Stack>
  )
}

export default AddressDisplay
